<template>
  <div>
    <b-row>
      <b-col class="col-12 col-md-6 col-xl-3 mb-1 d-flex align-items-stretch" v-for="(card, index) in overview_cards" :key="index" > 
        <b-card class="w-100">
          <div class="d-flex justify-content-between">
            <div>
              <h4>{{ card.title }}</h4>
              <p>{{ card.subtitle }}</p>
              <b-link @click="card.route ? $router.push(card.route) : redirectTo(card.external_route)">{{ card.button_text }}</b-link>
            </div>
            <div class="ml-1">
              <div 
                class="container-icon-collabs"
                :style="`background-color: ${card.backgrounds.selected};`"
                @mouseover="hoverAction(index)"
                @mouseout="hoverOutAction(index)"
                @click="card.route ? $router.push(card.route) : redirectTo(card.external_route)"
              >
                <b-img :src="card.icon" class="icon-collabs" :ref="`icon_collabs_${index}`"></b-img>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>    
    </b-row>

    <b-row class="m-0">
      <b-col class="w-100 text-white p-0 mb-2">
        <b-card class="h-100 w-100 col">
          <div class="w-100 d-flex justify-content-between mb-1">
            <h3>{{ $t('creator.creatorsInterest') }}</h3>
            <b-link @click="$router.push({name: 'search_proposal'})">
              <span class="avenir-medium cursor-pointer">{{ $t('creator.viewAlls') }}</span>
            </b-link>
          </div>
          <component :is="vue_horizontal" responsive class="horizontal-proposal-dashboard" :displacement="0.3" snap="center" v-if="loaded" id="horizontal-proposal-dashboard">
            <card-category
              v-for="(item, index) in results" :key="index"
              :proposal="item"
              :width="'300px'"
              :countries="countries"
              class="mr-1"
            />
          </component>
        </b-card>
      </b-col>

      <b-col class="col-12 text-white p-0">
        <b-card class="h-100">
          <div class="w-100 d-flex justify-content-between mb-1">
            <h3>{{ $t('creator.collabby') }}</h3>
            <b-link @click="$router.push({name: 'search_proposal'})">
              <span class="avenir-medium cursor-pointer">{{ $t('creator.viewAlls') }}</span>
            </b-link>
          </div>
    
          <component :is="vue_horizontal" responsive :displacement="0.3" snap="center" id="horizontal-networks-dashboard">
            <card-network  v-for="(item, index) in network"
              :key="index"
              :net="item"
              :icon="getIconWhite(item)"
              :img="getUrlCategory(item)"
            />
          </component>
        </b-card>
      </b-col>
      
      <b-col class="p-0">
        <card-casting-call
          :disabled_btn="false"
          :btn_action="redirectCreateCasting"
          :textInfo="$t('creator.chooseChampion')"
          :subtitle="$t('creator.directRelation')"
          :textButton="avaible_plan ? $t('dashboard.create_casting_call') : $t('creator.upgrade_to_create_castings')"
          :img="getUrlCategory('home_casting_call')"
          :can_create_casting="avaible_plan"
        />
      </b-col>
    </b-row>
  </div>
</template>
  
<script>
import { 
  BCol,
  BCard,
  BRow,
  BLink,
  BImg,
} from "bootstrap-vue";
import cardCategory from "@/views/pages/proposals/cardCategory.vue";
import service from "@/services/proposals";
import cardNetwork from "@/views/pages/proposals/cardNetwork.vue";
import { getIconWhite } from '@/libs/utils/icons';
import { getUrlCategory } from '@/libs/utils/urls';
import { getMotivationalPhrase, getCountries } from '@/libs/utils/others';

export default {
  name: "brandOverview",
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    cardCategory,
    cardNetwork,
    BImg,
    cardCastingCall: () => import('@/views/components/proposals/cardCastingCall.vue'),
  },
  props: {
    avaible_plan: {
      type: Boolean,
    }
  },
  data() {
    return {
      getIconWhite,
      getUrlCategory,
      countries: getCountries(),
      vue_horizontal: null,
      network: ['tiktok', 'instagram','youtube','facebook','linkedin','twitch','twitter','spotify','pinterest','blog','snapchat'],
      loaded: false,
      results: false,
      overview_cards: [
        {
          title: this.$t('creator.findCreatorTitle'),
          subtitle: this.$t('creator.findCreatorSub'),
          button_text: this.$t('creator.see_proposals'),
          icon: require('@/assets/images/icons/campaign-dashboard.svg'),
          route: {name: 'search_proposal'},
          backgrounds: {
            container: '#007acc',
            container_hover: '#007acc59',
            filter_svg: 'invert(70%) sepia(99%) saturate(2200%) hue-rotate(210deg) brightness(90%) contrast(91%)',
            selected: '#007acc',
          }
        },
        {
          title: this.$t('creator.searchGlobal'),
          subtitle: this.$t('creator.searchGlobalSub'),
          button_text: this.$t('creator.goToSearch'),
          icon: require('@/assets/images/icons/mistery.svg'),
          route: {name: 'auth-search'},
          backgrounds: {
            container: '#cc00c4',
            container_hover: '#cc00c52f',
            filter_svg: 'invert(80%) sepia(99%) saturate(2200%) hue-rotate(300deg) brightness(90%) contrast(91%)',
            selected: '#cc00c4',
          }
        },
        {
          title: this.$t('creator.innerCampaigns'),
          subtitle: this.$t('creator.innerCampaignsSub'),
          button_text: this.$t('creator.learnMore'),
          icon: require('@/assets/images/icons/flash-dashboard.svg'),
          route: {name: 'campaigns'},
          backgrounds: {
            container: '#00c4cc',
            container_hover: '#00c5cc33',
            filter_svg: 'invert(70%) sepia(50%) saturate(1500%) hue-rotate(180deg) brightness(85%) contrast(91%)',
            selected: '#00c4cc',
          }
        },
        {
          title: this.$t('creator.customServide'),
          subtitle: this.$t('creator.personaliText'),
          button_text: this.$t('creator.requestProposals'),
          icon: require('@/assets/images/icons/user-dashboard.svg'),
          external_route: 'https://brandme.la/servicios/',
          backgrounds: {
            container: '#00cc7a',
            container_hover: '#00cc7a38',
            filter_svg: 'invert(59%) sepia(72%) saturate(1352%) hue-rotate(135deg) brightness(92%) contrast(88%)',
            selected: '#00cc7a',
          }
        }
      ],
    };
  },
  async mounted() {
    this.vue_horizontal = (await import('vue-horizontal')).default;
    this.getProposals();
  },
  methods: {
    hoverAction(index) {
      this.overview_cards[index].backgrounds.selected = this.overview_cards[index].backgrounds.container_hover;
      this.$refs[`icon_collabs_${index}`][0].style.filter = this.overview_cards[index].backgrounds.filter_svg;
    },
    hoverOutAction(index) {
      this.overview_cards[index].backgrounds.selected = this.overview_cards[index].backgrounds.container;
      this.$refs[`icon_collabs_${index}`][0].style.filter = '';
    },
    redirectTo(external_route) {
      window.location.href = external_route;
    },
    getProposals() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: getMotivationalPhrase(),
      });
      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: getMotivationalPhrase(),
          });
        }
      }, 5000);
      service.getFavoriteProposals().then((response) => {
        this.results = response.results
        this.loaded = true;
        setTimeout(() => {
          clearInterval(this.interval_loading)
          this.$vs.loading.close()
        }, 500);
      });
    },
    redirectCreateCasting() {
      if (this.avaible_plan) this.$router.push({ name: 'steps_casting_call', params: {step: 'select-service'}})
      else this.$router.push({name: 'pages-account-setting', params: { section: 'membership'}})
    }
  },
};
</script>
<style>
#horizontal-proposal-dashboard .v-hl-container {
  padding: 0px !important;
} 
#horizontal-networks-dashboard .v-hl-container {
  padding: 0px !important;
}
</style>
<style lang="scss" scoped>
.container-icon-collabs {
  height: auto;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .icon-collabs {
    height: 30px;
    width: 30px;
  }

  &:hover {
    transition: all 500ms;
  }
  &:not(:hover) {
    transition: all 500ms;
  }
}


.horizontal >>> .v-hl-btn svg {
  border-radius: 0 !important;
  margin: 0 !important;
  padding: 8px !important;
  height: 100% !important;
  box-shadow: none !important;
  background: none !important;
}

.horizontal >>> .v-hl-btn-prev {
  background: linear-gradient(to left, #ffffff00 0, white 66%, white) !important;
  padding-right: 24px !important;
}

.horizontal >>> .v-hl-btn-next {
  background: linear-gradient(to right, #ffffff00 0, white 66%, white) !important;
  padding-left: 24px !important;
}
.horizontal-gray-casting-calls  >>> .v-hl-btn-prev {
  background: linear-gradient(to right, #ececec 0, #ffffff00 66%, #ffffff00) !important;
}
.horizontal-gray-casting-calls  >>> .v-hl-btn-next {
  background: linear-gradient(to right, #ffffff00 0, #ececec 66%, #ececec) !important;
}
.horizontal >>> .v-hl-btn {
  top: 0 !important;
  bottom: 0 !important;
  transform: translateX(0) !important;
}
</style>